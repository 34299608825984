import * as Sentry from '@sentry/nextjs';

export default function captureException(errorInfo: any) {
    if (typeof window !== 'undefined') {
        if (window.location.hostname === 'rumblekongleague.com') {
            Sentry.captureException(errorInfo);
        } else {
            console.error(errorInfo);
        }
    }
}
